/** focus the buttons for expandable content when the relevant checkbox is focused
 *  ------------------------------------------------------------------------------------------------
**/

const expandableCheckFocus = (e) => {
	// console.log(e.currentTarget.id);
	const target = document.querySelectorAll(`label[for='${e.currentTarget.id}']`);
	// console.log(target);

	(target || []).forEach(
		(label) => {
			label.classList.add('expandable-content-trigger--input-has-focus');
		},
	);
};

const expandableCheckBlur = (e) => {
	const target = document.querySelectorAll(`label[for='${e.currentTarget.id}']`);

	(target || []).forEach(
		(label) => {
			label.classList.remove('expandable-content-trigger--input-has-focus');
		},
	);
};

const expandableCheckChange = (e) => {
	const target = document.querySelectorAll(`label[for='${e.currentTarget.id}']`);

	if (e.currentTarget.checked) {
		(target || []).forEach(
			(label) => {
				label.classList.add('expandable-content-trigger--input-is-active');
			},
		);
	} else {
		(target || []).forEach(
			(label) => {
				label.classList.remove('expandable-content-trigger--input-is-active');
			},
		);
	}
};

/** Content toggles that use the 'expandable-content-trigger--exclusive' class close other content
 *  ------------------------------------------------------------------------------------------------
 *  if we find a parent with expandable-content-trigger-exclusivity-wrap we scope to that
 *  otherwise this is scoped to body
 *  this allows for something closer to a tabs system
 *  although it doesn't have a no-js fallback currently
**/
const closeAdjacentContent = (e) => {
	const scopedParent = e.currentTarget.closest('.expandable-content-trigger-exclusivity-wrap');
	const scope = scopedParent || document.body;
	const checkboxesInScope = scope.querySelectorAll('.expandable-content > input[type=checkbox], .expandable-content__content--isolated > input[type=checkbox]');

	(checkboxesInScope || []).forEach(
		(check) => {
			// don't touch the content we're targetting with 'for'
			if (check.id !== e.currentTarget.htmlFor) {
				// eslint-disable-next-line no-param-reassign
				check.checked = false;
				// changing checked in js doesn't trigger the listener so we manually call this
				expandableCheckChange({
					currentTarget: check,
				});
			}
		},
	);
};

/** Initialise listeners
 *  ------------------------------------------------------------------------------------------------
**/
export const init = () => {
	// if we want legacy support for :focus-within to expand content we can use JS for that here...
	// const expandableContent = document.querySelectorAll('.expandable-content__content');
	// (expandableContent || []).forEach(
	// (content) => {
	// // listen for focus inside
	// },
	// );

	const expandableCheckboxes = document.querySelectorAll('.expandable-content > input[type=checkbox], .expandable-content__content--isolated > input[type=checkbox]');
	(expandableCheckboxes || []).forEach(
		(check) => {
			check.addEventListener('focus', expandableCheckFocus, false);
			check.addEventListener('blur', expandableCheckBlur, false);
			check.addEventListener('change', expandableCheckChange, false);
		},
	);

	const exclusiveExpanders = document.querySelectorAll('.expandable-content-trigger--exclusive');
	(exclusiveExpanders || []).forEach(
		(exclusiveToggle) => {
			exclusiveToggle.addEventListener('click', closeAdjacentContent, false);
		},
	);
};

export default init;
