/** navigating book editions
 *  ------------------------------------------------------------------------------------------------
**/

/** activating editions and links by isbn
 *  ------------------------------------------------------------------------------------------------
**/
const switchEdition = (e) => {
	const targetISBN = e.currentTarget.getAttribute('data-isbn');

	// console.log(targetISBN);

	if (targetISBN) {
		// clear the old ones
		// this is a lame way to do this but we have very defined structure for now....
		const activeSiblingButts = e.currentTarget.parentNode.parentNode.parentNode.parentNode.querySelectorAll('.MannequinBookCoverAndLinks__edition-nav-button--active');
		const activeSiblingSections = e.currentTarget.parentNode.parentNode.parentNode.parentNode.querySelectorAll('.MannequinBookCoverAndLinks__details--active');
		const activeSiblingImages = e.currentTarget.parentNode.parentNode.parentNode.parentNode.querySelectorAll('.MannequinBookCoverAndLinks__cover--active');

		(activeSiblingButts || []).forEach(
			(activeButt) => {
				activeButt.classList.remove('MannequinBookCoverAndLinks__edition-nav-button--active');
			},
		);
		(activeSiblingSections || []).forEach(
			(activeSection) => {
				activeSection.classList.remove('MannequinBookCoverAndLinks__details--active');
			},
		);
		(activeSiblingImages || []).forEach(
			(activeSection) => {
				activeSection.classList.remove('MannequinBookCoverAndLinks__cover--active');
			},
		);

		// add the new classes
		const targetSection = document.querySelectorAll(`#details-${targetISBN}`);
		(targetSection || []).forEach(
			(sectionToShow) => {
				sectionToShow.classList.add('MannequinBookCoverAndLinks__details--active');
			},
		);
		// only remove old image if we found a new one
		const targetImage = document.querySelectorAll(`#cover-${targetISBN}`);
		if (targetImage && targetImage.length > 0) {
			(targetImage || []).forEach(
				(imageToShow) => {
					imageToShow.classList.add('MannequinBookCoverAndLinks__cover--active');
				},
			);
		} else {
			const defaultImage = e.currentTarget.parentNode.parentNode.parentNode.parentNode.querySelectorAll('.MannequinBookCoverAndLinks__cover--default');
			(defaultImage || []).forEach(
				(imageToShow) => {
					imageToShow.classList.add('MannequinBookCoverAndLinks__cover--active');
				},
			);
		}

		e.currentTarget.classList.add('MannequinBookCoverAndLinks__edition-nav-button--active');
	}
};

const pixelTrack = (e) => {
	// console.log(e.currentTarget.getAttribute('href'));
	// console.log(e.currentTarget.getAttribute('data-current-isbn'));
	// console.log(e.currentTarget.innerText);
	if (typeof fbq === 'function') {
		// eslint-disable-next-line no-undef
		fbq('trackCustom', 'buyLink', { isbn: e.currentTarget.getAttribute('data-current-isbn'), button: e.currentTarget.innerText });
		// console.log('sent custom event');
	} else {
		// console.log('fbq not active');
	}
};

// init listeners
export const init = () => {
	const editionButtons = document.querySelectorAll('.MannequinBookCoverAndLinks__edition-nav-button');
	(editionButtons || []).forEach(
		(butt) => {
			butt.addEventListener('click', switchEdition, false);
		},
	);

	const buyLinks = document.querySelectorAll('.MannequinBookCoverAndLinks__details__links a');
	(buyLinks || []).forEach(
		(buyLink) => {
			buyLink.addEventListener('mousedown', pixelTrack, false);
		},
	);
};

export default init;
