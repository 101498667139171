export const stickyHeightCheck = () => {
	const sticky = document.querySelectorAll('.u-stick-if-possible, .wp-block-column.is-style-sticky');

	(sticky || []).forEach(
		(sticker) => {
			if (sticker.offsetHeight > document.documentElement.clientHeight) {
				sticker.classList.add('js--sticker--overflowing');
			} else {
				sticker.classList.remove('js--sticker--overflowing');
			}
		},
	);
};

/** Initialise listeners
 *  ------------------------------------------------------------------------------------------------
**/
export const init = () => {
	/** Add class to sticky sections that are taller than the screen
	 *  ----------------------------------------------------------------------------------------------
	 *  We only do this once on page load (after css has loaded)
	 *  We can also do this on page resize but it feels like a low priority!
	**/
	if (document.readyState !== 'complete') {
		window.addEventListener('load', stickyHeightCheck, false);
	} else { // `DOMContentLoaded` has already fired
		stickyHeightCheck();
	}
};

export default init;
