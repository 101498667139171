/** imports
 *  ------------------------------------------------------------------------------------------------
**/
import { init as formsInit } from './forms';
import { init as genericIntersectionInit } from './generic-intersection';
import { init as carouselInit } from './carousels';
import { init as stickyInit } from './sticky';
import { init as bookEditionsInit } from './book-editions';
import { init as expandableInit } from './expandable';
import { init as dismissibleInit } from './dismissible';


/** Feature detect
 *  ------------------------------------------------------------------------------------------------
**/
// feature detect intersection observer
// we disable some styling and don't try to fade images if it's not supported
const gotIntersction = (
	('IntersectionObserver' in window)
	&& ('IntersectionObserverEntry' in window)
	&& ('intersectionRatio' in window.IntersectionObserverEntry.prototype)
	&& ('isIntersecting' in window.IntersectionObserverEntry.prototype)
);

document.documentElement.classList.add(gotIntersction ? 'got-intersection' : 'no-intersection');


/** Init everything that interacts with DOM (after dom is ready)
 *  ------------------------------------------------------------------------------------------------
**/
const init = () => {
	// validation listeners for forms
	formsInit();
	// allowing elements to opt in to generic interesection observation
	// by adding .js--wants-interestion
	genericIntersectionInit();
	// carousels
	carouselInit();
	// stickers
	stickyInit();
	// book editions nav
	bookEditionsInit();
	// some focus and accessibility helpers for expandable content
	expandableInit();
	// dismissible notifications etc.
	dismissibleInit();
};

if (document.readyState === 'loading') { // Loading hasn't finished yet
	// DOMContentLoaded can sometimes fire very late...
	// it fires after all defer scripts are loaded
	// however the readystatechange changes to interactive BEFORE defer scripts fire...
	// document.addEventListener('DOMContentLoaded', init, false);
	document.addEventListener('readystatechange', (event) => {
		if (event.target.readyState === 'interactive') {
			init();
		}
		// event.target.readyState === 'complete' is available and equivelant to DOMContentLoaded
	});
} else { // readystate is already past loading
	init();
}
