/** focus the buttons for expandable content when the relevant checkbox is focused
 *  ------------------------------------------------------------------------------------------------
**/

const dismissContent = (e) => {
	// console.log(e.currentTarget.id);
	const target = document.querySelectorAll(`#${e.currentTarget.getAttribute('data-target')}`);
	// console.log(target);

	(target || []).forEach(
		(dismiss) => {
			dismiss.classList.add('js--dismissible--dismissed');
		},
	);
};


/** Initialise listeners
 *  ------------------------------------------------------------------------------------------------
**/
export const init = () => {
	const dismissibleButtons = document.querySelectorAll('.js--dismissible__close');
	(dismissibleButtons || []).forEach(
		(butt) => {
			butt.addEventListener('click', dismissContent, false);
		},
	);
};

export default init;
